@use "@angular/material" as mat;
@import "material-design-icons/iconfont/material-icons.css";

@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/maps";
@import "../node_modules/bootstrap/scss/mixins";
@import "../node_modules/bootstrap/scss/root";

@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/utilities/api";

// 8. Add additional custom code here
@include mat.core();
$main-palette: (
  50: #effaea,
  100: #d7f1ca,
  200: #bce7a8,
  300: #a1dd84,
  400: #8bd568,
  500: #76ce4d,
  600: #67bd45,
  700: #52a83b,
  800: #3e9431,
  900: #0f721e,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: white,
    A700: white
  )
);
$second-palette: (
  50: #f5f5f5,
  100: #e9e9e9,
  200: #d9d9d9,
  300: #c4c4c4,
  400: #9d9d9d,
  500: #7b7b7b,
  600: #555555,
  700: #434343,
  800: #262626,
  900: #000000,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: white
  )
);
$warn-palette: (
  50: #ffeaed,
  100: #ffcacf,
  200: #f69493,
  300: #ed6969,
  400: #f74342,
  500: #fc2b22,
  600: #ed1c23,
  700: #db071e,
  800: #ce0015,
  900: #c10004,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: white,
    500: white,
    600: white,
    700: white,
    800: white,
    900: white
  )
);
$main-pallete-stolmax: mat.define-palette($main-palette, 700, 300, 900);
$second-pallete-stolmax: mat.define-palette($second-palette, 300, 100, 500);
$warn-pallete-stolmax: mat.define-palette($warn-palette, 700);

$stolmax-typography: mat.define-typography-config(
  $font-family: "Lato"
);

$stolmax-theme: mat.define-light-theme(
  (
    color: (
      primary: $main-pallete-stolmax,
      accent: $second-pallete-stolmax,
      warn: $warn-pallete-stolmax
    ),
    typography: $stolmax-typography
  )
);
@include mat.all-component-themes($stolmax-theme);

/* Import the font using @font-face */
@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src:
    local("Lato"),
    local("Lato-Regular"),
    url("assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}

/* You can add global styles to this file, and also import other style files */
body,
html,
app-root {
  width: 100vw;
  min-height: 640px;
  min-width: 360px;
  max-width: 100vw;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  font-display: swap;
}

.h-100 {
  height: 100%;
}

.h-50 {
  height: 50%;
}

.h-90 {
  height: 90%;
}

.h-80 {
  height: 80%;
}

.h-10 {
  height: 10%;
}

.h-20 {
  height: 20%;
}

.h-75 {
  height: 75%;
}

.h-25 {
  height: 25%;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
}
